import { MyContact } from "../components/contact"

const Contact = () => {
    return (
        <>
            <MyContact />
        </>
    )
}

export { Contact }