import { AboutMe } from "../components/about-me"

const About = () => {
    return (
        <>
            <AboutMe />
        </>
    )
}

export { About }