import { MyExperiences } from "../components/my-experiences"

const Experiences = () => {
    return (
        <>
            <MyExperiences />
        </>
    )
}

export { Experiences }