import { MyPorfolio } from "../components/portfolio"

const Portfolio = () => {
    return (
        <>
            <MyPorfolio />
        </>
    )
}

export { Portfolio }