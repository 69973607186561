import { CV } from "../components/curriculum"

const Curriculum = () => {
    return (
        <>
            <CV />
        </>
    )
}

export { Curriculum }