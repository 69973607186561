import { Menu } from "../components/home-page"

const Home = () => {
    return (
        <>
            <Menu />
        </>
    )
}

export { Home }